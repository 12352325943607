import { buildQuery } from "@/utils.js";

export function getSeries() {
  const url = "/api/machines/series";
  return fetch(url)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    });
}

export function getModels() {
  const url = "/api/machines/models";
  return fetch(url)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    });
}

export async function getNumMachines() {
  const url = "/api/machines/count";
  const response = await fetch(url, {
    method: "GET",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("Number of machines not found");
  }
}

export async function getNumMachinesGama() {
  const url = "/api/machines/count_gama";
  const response = await fetch(url, {
    method: "GET",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("Number of machines not found");
  }
}

export async function getMachines(serieId, modelId, clientId, activeFilter) {
  let url = "/api/machines/machines";
  let params = [];
  if (serieId != null) params.push("serie_id=" + serieId);
  if (modelId != null) params.push("model_id=" + modelId);
  if (clientId != null) params.push("client_id=" + clientId);
  if (activeFilter != null) params.push("active=" + activeFilter);
  let queryParams = params.join("&");
  if (queryParams.length > 0) url += "?" + queryParams;

  const response = await fetch(url, {
    method: "GET",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("The machine entered does not exist");
  }
}

export async function getMachinesData(
  serieId,
  modelId,
  clientId,
  labelId,
  machineNumber,
  activeFilter,
  currentPage,
  itemsPerPage,
  sortBy = "machine_number",
  sortDesc = false
) {
  let url = "/api/machines/machines-data";
  let params = [];
  if (serieId != null) params.push("serie_id=" + serieId);
  if (modelId != null) params.push("model_id=" + modelId);
  if (clientId != null) params.push("client_id=" + clientId);
  if (labelId != null) params.push("label_id=" + labelId);
  if (machineNumber != null) params.push("machine_number=" + machineNumber);
  if (activeFilter != null) params.push("active=" + activeFilter);
  if (currentPage != null) params.push("current_page=" + currentPage);
  if (itemsPerPage != null) params.push("items_per_page=" + itemsPerPage);
  if (sortBy != null) params.push("sort_by=" + sortBy);
  if (sortDesc != null) params.push("sort_desc=" + (sortDesc ? 1 : 0));
  let queryParams = params.join("&");
  if (queryParams.length > 0) url += "?" + queryParams;

  const response = await fetch(url, {
    method: "GET",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("The machine entered does not exist");
  }
}

export async function getMachinesByClient(clientId) {
  let url = "/api/machines/machines-client/" + clientId;

  const response = await fetch(url, {
    method: "GET",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("The machine entered does not exist");
  }
}

export async function getMachinesByNumber(machineNumber) {
  let url = "/api/machines/machines-number/" + machineNumber;

  const response = await fetch(url, {
    method: "GET",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("The machine number entered does not exist");
  }
}

export function deleteMachine(id) {
  let url = "/api/machines/machine/" + id;
  return fetch(url, {
    method: "DELETE",
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    });
}

export async function getMachine(id) {
  const url = "/api/machines/machine/" + id;
  const response = await fetch(url, {
    method: "GET",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("The machine entered does not exist");
  }
}

export function addMachine(machine) {
  const url = "/api/machines/machines";
  const response = fetch(url, {
    method: "POST",
    body: JSON.stringify(machine),
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("The machine can not be created");
  }
}

export function updateMachine(id, machine) {
  const url = "/api/machines/machine/" + id;
  return fetch(url, {
    method: "PUT",
    body: JSON.stringify(machine),
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    });
}

export async function updateMachineImage(id, imageFile) {
  const url = "/api/machines/machine-image/" + id;
  const formData = new FormData();
  formData.append("image", imageFile);
  const response = await fetch(url, {
    method: "PUT",
    body: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error(response.status);
  }
}

export async function getMachineImage(id) {
  const url = "/api/machines/machine-image/" + id;
  const response = await fetch(url, {
    method: "GET",
  });
  if (response.status == 200) {
    const filename = response.headers
      .get("Content-Disposition")
      .split("filename=")[1];
    const imageBlob = await response.blob();

    return { filename: filename, image: imageBlob };
  } else {
    throw new Error("The user image profile does not exist");
  }
}

export async function removeMachineImage(id) {
  const url = "/api/machines/machine-image/" + id;
  const response = await fetch(url, {
    method: "DELETE",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error(response.status);
  }
}

export function registerMachine(machine_id, client_id) {
  const url = "/api/machines/register";
  return fetch(url, {
    method: "POST",
    body: JSON.stringify({
      machine_id: machine_id,
      client_id: client_id,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    });
}

export async function getUnregisteredMachines() {
  const url = "/api/machines/unregistered";
  const response = await fetch(url, {
    method: "GET",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("Machines could not be found");
  }
}

export async function activationMachine(id, activationDate, file) {
  const url = "/api/machines/activation/" + id;
  const formData = new FormData();
  formData.append("date", activationDate);
  formData.append("file", file);

  const response = await fetch(url, {
    method: "PUT",
    body: formData,
    headers: {
      //Luego se elimina, es para tener trazada la peticion
      "Content-Type": "multipart/form-data",
    },
  });
  if (response.status == 200) {
    return response.json();
  } else {
    const errorDetails = await response.json();
    throw new Error(JSON.stringify(errorDetails));
  }
}

export async function reasignClient(machine_id, client_id) {
  const url = "/api/machines/reasign_client";
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify({
      machine_id: machine_id,
      client_id: client_id,
    }),
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("The machine client can not be reasigned");
  }
}

export async function getWarrantyConditions() {
  const url = "/api/machines/warranty_conditions";
  const response = await fetch(url, {
    method: "GET",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("Warranty conditions coud not be found");
  }
}

export async function getNextMaintenances() {
  const url = "/api/machines/next_maintenances";
  const response = await fetch(url, {
    method: "GET",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("Error getting next maintenances");
  }
}

export async function getMachinesPendingActivate() {
  const url = "/api/machines/machines_pending_activate";
  const response = await fetch(url, {
    method: "GET",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("Error getting machines");
  }
}

export async function getActivatedMachinesLastYear() {
  const url = "/api/machines/activated_machines_last_year";
  const response = await fetch(url, {
    method: "GET",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("Error getting machines");
  }
}

export async function getMachineLabels(id) {
  const url = "/api/machines/machine-labels/" + id;
  const response = await fetch(url, {
    method: "GET",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("The machine entered does not exist");
  }
}

export async function addLabelForMachine(machine_id, label_id) {
  const url = "/api/machines/machine-label/";
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify({
      machine_id: machine_id,
      label_id: label_id,
    }),
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("The machine entered does not exist");
  }
}

export function removeLabelFromMachine(machine_id, label_id) {
  const url_query = buildQuery({
    machine_id: machine_id,
    label_id: label_id,
  });
  let url = "/api/machines/remove-label?" + url_query;
  return fetch(url, {
    method: "DELETE",
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    });
}

export async function sendWarrantyRequest(
  machineId,
  clientId,
  distributorId,
  type,
  description,
  images,
  parts,
  sendMode
) {
  const url = "/api/machines/warranty_request";
  const formData = new FormData();

  formData.append("machineId", machineId);
  formData.append("clientId", clientId);
  formData.append("distributorId", distributorId);
  formData.append("type", type);
  formData.append("description", description);
  for (var iImage = 0; iImage < images.length; iImage++)
    formData.append("images", images[iImage]);
  for (var iPart = 0; iPart < parts.length; iPart++) {
    if (JSON.stringify(parts[iPart]) !== "{}")
      formData.append("parts", JSON.stringify(parts[iPart]));
  }
  formData.append("sendMode", sendMode);

  const response = await fetch(url, {
    method: "POST",
    body: formData,
    headers: {
      //Luego se elimina, es para tener trazada la peticion
      "Content-Type": "multipart/form-data",
    },
  });
  if (response.status == 200) {
    return response.json();
  } else {
    const err = await response.json();
    throw new Error(err.error);
  }
}

export async function getTechnicalSheet(id, serial) {
  const url = "/api/machines/machine/technical-sheet/" + id;
  const response = await fetch(url, {
    method: "GET",
  });
  if (response.status == 200) {
    const filename = "Ficha técnica - " + serial + ".pdf";
    const fileBlob = await response.blob();

    // Crear un enlace temporal para descargar el archivo
    const link = document.createElement("a");

    // Configurar los atributos del enlace para descargar el archivo
    link.href = URL.createObjectURL(fileBlob);
    link.target = "_blank";
    link.rel = "noopener noreferrer";
    link.download = filename;
    link.click();

    // Liberar recursos del enlace temporal
    URL.revokeObjectURL(link.href);
    return true;
  } else {
    throw new Error("Error to get the distributors template");
  }
}

export async function getMachineWorkingHours(machine_id) {
  const url = "/api/machines/working-hours/" + machine_id;
  const response = await fetch(url, {
    method: "GET",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("The machine entered does not exist");
  }
}

export function updateMachineWorkingHours(machineId, hourlySchedule) {
  const url = "/api/machines/working-hours/" + machineId;
  return fetch(url, {
    method: "PUT",
    body: JSON.stringify(hourlySchedule),
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    });
}

export async function exportMachineMapTracking(geoJsonMarkers) {
  const url = "/api/machines/map-tracking";
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(geoJsonMarkers),
  });
  if (response.status == 200) {
    const tracking_date = geoJsonMarkers.features[0].properties.date;
    const filename = "geo_markers_" + tracking_date + ".zip";
    const fileBlob = await response.blob();

    const link = document.createElement("a");

    link.href = URL.createObjectURL(fileBlob);
    link.download = filename;
    link.click();

    URL.revokeObjectURL(link.href);
    return true;
  } else {
    throw new Error("Error getting the map tracking's shp file.");
  }
}

export async function getMachineVariables(machine_id) {
  const url = "/api/machines/variables/" + machine_id;
  const response = await fetch(url, {
    method: "GET",
  });
  if (response.status == 200) {
    return response.json();
  } else {
    throw new Error("The machine entered does not exist");
  }
}
